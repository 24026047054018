import React from "react";
import './App.css';

import axios from "axios";
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';

import Modal from 'react-modal';

class Keyboard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};

      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    press(number) {
        this.props.callback(number);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
      }
    
      componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }
    
      /**
       * Set the wrapper ref
       */
      setWrapperRef(node) {
        this.wrapperRef = node;
      }

      handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.props.close();
        }
      }
  
    render() {
        if (this.props.open)
        return (
            <div className="keyboardContainer">
                <div ref={this.setWrapperRef} className="keyboardContent">
                    <div className="keyboardRow">
                        <button onClick={() => this.press(1)} className="keyboardBtn">1</button>
                        <button onClick={() => this.press(2)} className="keyboardBtn">2</button>
                        <button onClick={() => this.press(3)} className="keyboardBtn">3</button>
                    </div>
                    <div className="keyboardRow">
                        <button onClick={() => this.press(4)} className="keyboardBtn">4</button>
                        <button onClick={() => this.press(5)} className="keyboardBtn">5</button>
                        <button onClick={() => this.press(6)} className="keyboardBtn">6</button>
                    </div>
                    <div className="keyboardRow">
                        <button onClick={() => this.press(7)} className="keyboardBtn">7</button>
                        <button onClick={() => this.press(8)} className="keyboardBtn">8</button>
                        <button onClick={() => this.press(9)} className="keyboardBtn">9</button>
                    </div>
                    <div className="keyboardRow">
                        <button onClick={() => this.press('DEL')} className="keyboardBtn">DEL</button>
                        <button onClick={() => this.press(0)} className="keyboardBtn">0</button>
                        <button onClick={() => this.press('OK')} className="keyboardBtn">OK</button>
                    </div>
                </div>
            </div>
        )
    }
  }

  export default Keyboard;