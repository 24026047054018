import React from "react";
import './App.css';

import axios from "axios";
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask';

import Modal from 'react-modal';
import Keyboard from "./Keyboard";

import io from 'socket.io-client';

class Form extends React.Component {
    constructor(props) {
      super(props);
      this.socket = null;
      this.state = {
        btnBlockade : false,
        step : 1,
        phone : '',
        code : '',
        phoneMask : createDefaultMaskGenerator('999-999-999'),
        codeMask : createDefaultMaskGenerator('9999'),
        openModal : false,
        errorMessage : '',
        openKeyboard : false
      };
      this.timeout = null;
    }

    componentDidMount() {
        this.socket = new WebSocket('ws://127.0.0.1:3435');
    }

    closeKeyboard = () => {
        this.setState({openKeyboard : false});
    }

    handleCode = (e) => {
        this.setState({code : e});
    }

    handlePhone = (e) => {
        this.setState({phone : e});
    }

    openModal = text => {
        this.setState({openModal : true, errorMessage : text});
    }

    closeModal = () => {
        this.setState({openModal : false, errorMessage : ''});
    }

    pressKyb = number => {
        if (this.state.step == 1) {
            if (number == 'OK') {
                this.closeKeyboard();
            } else {
                if (number == 'DEL') {
                    this.setState({phone : this.state.phone.slice(0, -1)});
                } else {
                    this.setState({phone : this.state.phone + number});
                }
            }
            
        }
        if (this.state.step == 2) {
            if (number == 'OK') {
                this.closeKeyboard();
            } else {
                if (number == 'DEL') {
                    this.setState({code : this.state.code.slice(0, -1)});
                } else {
                    this.setState({code : this.state.code + number});
                }
            }
            
        }
        
    }

    action = () => {
        this.setState({btnBlockade : true});
        
        if (this.state.step === 1) {
            const { phone } = this.state;
            console.log(phone.length)
            if (phone.length == 9) {
                const data = { phone: this.state.phone };
                axios.post('https://api.n.code-me.pl/api/v1/register', data)
                .then(response => {
                    if (response.data.error != undefined) {
                        this.openModal(response.data.message);
                        this.setState({btnBlockade : false});
                    }
                    if (response.data.result) {
                        this.setState({btnBlockade : false});
                        this.setState({ step: 2 });
                        this.timeout = setTimeout(() => {
                            this.setState({ step: 1, phone : '', code : '' });
                        }, 40000);
                    }
                })
                .catch(error => {
                    this.setState({btnBlockade : false});
                    alert('error');
                });
            } else {
                this.setState({btnBlockade : false});
                this.openModal('Wprowadzony nr telefonu ma nieprawidłową długość!');
            }
            
        }
        if (this.state.step === 2) {
            clearTimeout(this.timeout);
            const data = { phone: this.state.phone, code : this.state.code };
            axios.post('https://api.n.code-me.pl/api/v1/verify', data)
            .then(response => {
                console.log(response);
                if (response.data.error != undefined) {
                    this.setState({btnBlockade : false});
                    this.openModal(response.data.message);
                    this.timeout = setTimeout(() => {
                        this.setState({ step: 1, phone : '', code : '' });
                    }, 40000);
                }
                if (response.data.result) {
                    this.setState({btnBlockade : false});
                    this.socket.send('KEYBOARD' + response.data.slot);
                    this.setState({ step: 3})
                    setTimeout(() => {
                        this.setState({ step: 1, phone : '', code : '',})
                    }, 20000);
                }
            })
            .catch(error => {
                this.setState({btnBlockade : false});
                alert('error');
            });
        }
    }

    
  
    render() {
        if (this.state.step === 1)
        return (
            <div className="container">
                <div className="nunito nunito-dark form-title">
                    Podaj numer telefonu: {this.state.openKeyboard}
                </div>
                <div className="form-content">
                    <MaskedInput maskGenerator={this.state.phoneMask} type="phone" className="form-input" 
                    placeholder="XXX - XXX - XXX"
                    value={this.state.phone}
                    onChange={this.handlePhone}
                    onFocus={() => this.setState({openKeyboard : true})}
                    />
                    <button disabled={this.state.btnBlockade} onClick={this.action} className="form-btn nunito">ZATWIERDŹ</button>
                </div>
                <div className="nunito nunito-dark form-footer">
                    Podaj swój nr telefonu, na który dostaniesz SMS bezzwrotny z kodem. Wpisz ten kod w kolejnym kroku i otrzymaj sampel.
                    <br></br><br></br>
                    Podanie nr telefonu jest dobrowolne, aczkolwiek niezbędne w celu uzyskania kodu SMS
                </div>

                <Keyboard close={this.closeKeyboard} open={this.state.openKeyboard} callback={this.pressKyb}></Keyboard>
                <Modal className='modal' isOpen={this.state.openModal}>
                    <h2 className="nunito">Wystąpił błąd!</h2>
                    {this.state.errorMessage}
                    <button onClick={this.closeModal} className="form-btn nunito">ZAMKNIJ</button>
                </Modal>
            </div>
        );

        if (this.state.step === 2)
        return (
            <div className="container">
                <div className="nunito nunito-dark form-title">
                    Podaj kod z SMSA:
                </div>
                <div className="form-content">
                <MaskedInput maskGenerator={this.state.codeMask} type="text" className="form-input" 
                    placeholder="XXXX"
                    value={this.state.code}
                    onChange={this.handleCode}
                    onFocus={() => this.setState({openKeyboard : true})}
                    />
                    <button onClick={this.action} className="form-btn nunito">ZATWIERDŹ</button>
                    <button onClick={() => this.setState({step : 1})} className="form-btn2 nunito">POPRAW NUMER</button>
                </div>
                <div className="nunito nunito-dark form-footer">
                    Podaj swój nr telefonu, na który dostaniesz SMS bezzwrotny z kodem. Wpisz ten kod w kolejnym kroku i otrzymaj sampel.
                    <br></br><br></br>
                    Podanie nr telefonu jest dobrowolne, aczkolwiek niezbędne w celu uzyskania kodu SMS
                </div>

                <Keyboard close={this.closeKeyboard} open={this.state.openKeyboard} callback={this.pressKyb}></Keyboard>
                <Modal className='modal' isOpen={this.state.openModal}>
                    <h2 className="nunito">Wystąpił błąd!</h2>
                    {this.state.errorMessage}
                    <button onClick={this.closeModal} className="form-btn nunito">ZAMKNIJ</button>
                </Modal>
            </div>
        );

        if (this.state.step === 3)
        return (
            <div className="container">
                <div className="nunito nunito-dark title3">
                    ODBIERZ SWOJĄ PRÓBKĘ
                </div>
                <div className="nunito">
                    Dziękujemy za skorzystanie z automatu samplingowego.
                </div>
            </div>
        );
    }
  }

  export default Form;