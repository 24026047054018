import logo from './logo.svg';
import logofl from './assets/images/logo-stopka1.png';
import logohf from './assets/images/logo-hf-footer.svg';
import logonestle from './assets/images/logo_nestle.png';
import churros from './assets/images/churros.jpg';
import chocapic from './assets/images/chocapic.jpg';

import Form from './Form.js';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className='App-header'>
        <div className='App-container'>
          <img src={logonestle} className="App-logo" alt="logo" />
          <div className='title nunito'>
            ODBIERZ DARMOWĄ PRÓBKĘ
          </div>
          <div className='subtitle'>
            płatków Chocapic dla Twojego dziecka!
          </div>
          <img src={chocapic} className='productImg' />

          <Form></Form>

          <div className='footertitle nunito'>
          Jeden numer telefonu to jedna próbka.<br></br>
          Szczegółowy regulamin samplingu znajdziesz<br></br>
          na stronie <strong>www.sampling-cpw.pl</strong>
          </div>
        </div>

        
      </header>
      <footer className='App-footer'>
        <div className='App-footer-child App-footer-left'><img src={logohf} className='App-footer-logo' alt="logo" /></div>
        <div className='App-footer-child App-footer-right nunito'>
        High Frequency Sp. z o. o.<br></br>
Brzegowa 17, 30-235 Kraków<br></br>
www.highfrequency.pl
        </div>
      </footer>
    </div>
  );
}

export default App;
